import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "./SEO"
import { Box, Container, Flex, Heading, Link, Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"

interface LayoutProps {
  pageTitle: string
  children: React.ReactNode
}

const HomeLayout = ({ pageTitle, children }: LayoutProps): JSX.Element => {
  const images = useStaticQuery(graphql`
    query images {
      allFile(
        filter: {
          relativePath: { ne: "icon.png" }
          sourceInstanceName: { eq: "images" }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `)

  const [randomIdx, setRandomIdx] = React.useState(0)

  React.useEffect(() => {
    setRandomIdx(~~(Math.random() * images.allFile.nodes.length))
  }, [])

  let rotatingImage = getImage(images.allFile.nodes[randomIdx])

  return (
    <Flex flexDirection={"column"} id={"root"} pb={6}>
      <SEO title={"wryFi"} />
      <Box minH={"35vh"} id={"layout-row-top"}>
        <Container
          display={"flex"}
          flexDirection={"row"}
          id={"layout-container-top"}
          alignItems={"center"}
          justifyContent={"center"}
          minH={"35vh"}
        >
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mb={24}
            id={"home-content-top"}
          >
            <Heading mb={1}>
              <Link href={"/"}>wryFi</Link>
            </Heading>
            <Text as={"cite"} fontSize={"xl"}>
              {pageTitle}
            </Text>
          </Flex>
        </Container>
      </Box>

      <Box id={"layout-row-bottom"}>
        <Container
          display={"flex"}
          flexDirection={"column"}
          id={"layout-container-bottom"}
          boxShadow={"rgb(0 0 0 / 50%) 0px -6px 6px -6px"}
          justifyContent={"center"}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"center"}
            id={"home-content-bottom"}
          >
            <Box
              borderRadius={"50%"}
              overflow={"hidden"}
              w={"125px"}
              mt={"-63px"}
              boxShadow={"rgb(0 0 0 / 70%) 0px -4px 16px -4px"}
              as={"a"}
              href={"/"}
              zIndex={10}
              id={"home-link"}
            >
              {rotatingImage && (
                <GatsbyImage
                  image={rotatingImage}
                  alt={"random/rotating image"}
                  id={"home-content-image"}
                />
              )}
            </Box>
            {children}
          </Flex>
        </Container>
      </Box>
    </Flex>
  )
}

export default HomeLayout
